import toast from 'react-hot-toast';
import axios from 'axios';
import axiosRetry from 'axios-retry';

const ApiRoot = `${process.env.REACT_APP_HISTORY_API_ROOT}/me`;

axiosRetry(axios, {
  retries: 5,
  retryDelay: (retryCount) => retryCount * 3000,
});

const BuildQueryString = function (activityCode, instanceCode, courseTitle, surveyName, courseStartDate) {
  let queryString = '';

  if (activityCode !== '') {
    queryString += `activityCode=${activityCode}&`;
  }

  if (instanceCode !== '') {
    queryString += `instanceCode=${instanceCode}&`;
  }

  if (courseTitle !== '') {
    queryString += `courseTitle=${courseTitle}&`;
  }

  if (surveyName !== '') {
    queryString += `surveyName=${surveyName}&`;
  }

  if (courseStartDate !== '') {
    queryString += `courseStartDate=${courseStartDate}&`;
  }

  if (queryString !== '') queryString = queryString.slice(0, -1);

  return queryString;
};

const getMyEngagementHistory = async function (
  activityCode = '',
  instanceCode = '',
  courseTitle = '',
  surveyName = '',
  courseStartDate = '',
) {
  try {
    const queryString = BuildQueryString(activityCode, instanceCode, courseTitle, surveyName, courseStartDate);
    const result = await axios.get(`${ApiRoot}?${queryString}`);
    toast.success('Search has completed successfully');
    return result.data;
  } catch {
    toast.error('Error listing engagement history please try again');
    return [];
  }
};

export default getMyEngagementHistory;
