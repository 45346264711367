import { React } from 'react';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';

const HistoryModal = function (props) {
  const { history, show, handleClose } = props;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Engagement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <b>Course Code:</b>
          <br />
          {history?.activityCode}
        </p>
        <p>
          <b>Instance Code:</b>
          <br />
          {history?.instanceCode}
        </p>
        <p>
          <b>Course Start Date:</b>
          <br />
          {moment(history?.courseStartDate).format('DD/MM/yyyy')}
        </p>
        <p>
          <b>Course Title:</b>
          <br />
          {history?.courseTitle}
        </p>
        <p>
          <b>Survey:</b>
          <br />
          {history?.surveyName}
        </p>
        <p>
          <b>Sent On:</b>
          <br />
          {`${moment(history?.surveySentOn).format('DD/MM/yyyy')} @ ${moment(history?.surveySentOn).format('HH:mm:ss')}`}
        </p>
        <p className="url-container">
          <b>Survey URL:</b>
          <br />
          <a href={history?.surveyUri} title="Survey URL" target="_blank" rel="noreferrer">{history?.surveyUri}</a>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
HistoryModal.defaultProps = {
  history: null,
};
HistoryModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  history: PropTypes.shape({
    activityCode: PropTypes.string,
    instanceCode: PropTypes.string,
    courseStartDate: PropTypes.string,
    courseTitle: PropTypes.string,
    surveyName: PropTypes.string,
    surveySentOn: PropTypes.string,
    surveyUri: PropTypes.string,
  }),
};

export default HistoryModal;
