import { React } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const HistoryPagination = function (props) {
  const {
    gotoPage, previousPage, nextPage, canPreviousPage, canNextPage, pageCount, pageOptions, pageIndex,
  } = props;

  return (
    <div className="pagination">
      <div className="previous">
        <Button variant="success" size="sm" onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="btn-operation">
          {'<<'}
        </Button>
        {' '}
        <Button variant="success" size="sm" onClick={() => previousPage()} disabled={!canPreviousPage} className="btn-operation">
          {'<'}
        </Button>
        {' '}
      </div>
      <div className="center">
        {
          pageCount > 0
            ? (
              <span>
                <span>
                  Page
                  {' '}
                  <strong>
                    {pageIndex + 1}
                    {' '}
                    of
                    {' '}
                    {pageOptions.length}
                  </strong>
                  {' '}
                </span>
                <span>
                  | Go to page
                  {' '}
                  <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const moveToPage = e.target.value ? Number(e.target.value) - 1 : 0;
                      gotoPage(moveToPage);
                    }}
                    style={{ width: '100px' }}
                  />
                </span>
              </span>
            )
            : ''
        }
      </div>
      <div className="next">
        <Button variant="success" size="sm" onClick={() => nextPage()} disabled={!canNextPage} className="btn-operation">
          {'>'}
        </Button>
        {' '}
        <Button variant="success" size="sm" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="btn-operation">
          {'>>'}
        </Button>
        {' '}
      </div>
    </div>
  );
};
HistoryPagination.propTypes = {
  gotoPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageOptions: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
};

export default HistoryPagination;
