/* eslint-disable react/jsx-props-no-spreading */
import {
  React, useState, useEffect, useMemo,
} from 'react';
import { Table, Button } from 'react-bootstrap';
import { useTable, useSortBy, usePagination } from 'react-table';
import moment from 'moment';
import HistoryModal from './HistoryModal';
import HistoryPagination from './HistoryPagination';
import HistorySearch from './HistorySearch';
import getMyEngagementHistory from '../../Helpers/MeApi';

const Dashboard = function () {
  const [history, setHistory] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const handleClose = () => setShowModal(false);

  const handleShow = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Course Code',
        accessor: 'activityCode',
      },
      {
        Header: 'Instance Code',
        accessor: 'instanceCode',
      },
      {
        Header: 'Course Start Date',
        accessor: 'courseStartDate',
        className: 'd-none d-sm-table-cell',
        Cell: ({ value }) => (moment(value).format('DD/MM/yyyy')),
      },
      {
        Header: 'Course Title',
        accessor: 'courseTitle',
        className: 'd-none d-sm-table-cell',
      },
      {
        Header: 'Survey Name',
        accessor: 'surveyName',
        className: 'd-none d-sm-table-cell',
      },
      {
        Header: 'Sent On',
        accessor: 'surveySentOn',
        className: 'd-none d-sm-table-cell',
        Cell: ({ value }) => (moment(value).format('DD/MM/yyyy')),
      },
      {
        Header: '',
        id: 'action-link',
        // eslint-disable-next-line react/no-unstable-nested-components, react/prop-types
        Cell: ({ row }) => (
          // eslint-disable-next-line react/prop-types
          <Button variant="primary" size="sm" onClick={() => handleShow(row.original)} className="btn-view-details">
            View Details
          </Button>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    async function getHistory() {
      const data = await getMyEngagementHistory();
      setHistory(data);
      setNoRecordsFound(data.length === 0);
    }
    getHistory();
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    pageCount,
    canNextPage,
    canPreviousPage,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: history,
      initialState: {
        sortBy: [
          {
            id: 'surveySentOn',
            desc: false,
          },
        ],
        pageIndex: 0,
        pageSize: 20,
      },
    },
    useSortBy,
    usePagination,
  );

  const showSortIcon = (column) => {
    if (column.isSorted) {
      if (column.isSortedDesc) {
        return ' 🔽';
      }

      return ' 🔼';
    }

    return '';
  };

  const initialSearchValues = {
    activityCode: '',
    instanceCode: '',
    courseTitle: '',
    surveyName: '',
    courseStartDate: '',
  };

  const onSubmit = async (searchCriteria) => {
    const data = await getMyEngagementHistory(
      searchCriteria.activityCode,
      searchCriteria.instanceCode,
      searchCriteria.courseTitle,
      searchCriteria.surveyName,
      searchCriteria.courseStartDate,
    );
    setHistory(data);
    setNoRecordsFound(data.length === 0);
  };

  return (
    <div>
      <div className="intro-text">
        <p>
          Share your experience in our evaluation form to help us develop and improve our CPD. For some courses you
          may also be asked to make an action plan and reflect on the impact of your CPD after the course. These forms
          will also appear on your dashboard.
        </p>
      </div>
      <div className="FormWrapper">
        <HistorySearch onSubmit={onSubmit} initialValues={initialSearchValues} />
      </div>
      <div className="table-wrapper">
        <HistoryModal show={showModal} handleClose={handleClose} history={selectedRow} />
        <Table striped bordered hover {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps([column.getSortByToggleProps(), { className: column.className }])}>
                    {column.render('Header')}
                    <span>
                      {showSortIcon(column)}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {noRecordsFound
              ? (
                <tr>
                  <td colSpan={10}>
                    <span className="d-block invalid-feedback">
                      No engagement records were found
                    </span>
                  </td>
                </tr>
              )
              : (
                page.map(
                  (row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {
                          row.cells.map((cell) => (
                            <td {...cell.getCellProps([{ className: cell.column.className }])}>{cell.render('Cell')}</td>
                          ))
                        }
                      </tr>
                    );
                  },
                )
              )}
          </tbody>
        </Table>
        <HistoryPagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          pageOptions={pageOptions}
          pageIndex={pageIndex}
        />
      </div>
    </div>
  );
};

export default Dashboard;
