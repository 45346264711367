import { React } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  FormGroup, Row, Col, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

const HistorySearch = function (props) {
  const { onSubmit, initialValues } = props;

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      <Form>
        <Row>
          <FormGroup as={Col} className="form-group col-md-4 col-12">
            <label htmlFor="activityCode">Course code</label>
            <Field id="activityCode" name="activityCode" type="text" className="form-control" />
          </FormGroup>
          <FormGroup as={Col} className="form-group col-md-4 col-12">
            <label htmlFor="instanceCode">Instance code</label>
            <Field id="instanceCode" name="instanceCode" type="text" className="form-control" />
          </FormGroup>
          <FormGroup as={Col} className="form-group col-md-4 col-12">
            <label htmlFor="courseTitle">Course title</label>
            <Field id="courseTitle" name="courseTitle" type="text" className="form-control" />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup as={Col} className="form-group col-md-4 col-12">
            <label htmlFor="surveyName">Survey</label>
            <Field id="surveyName" name="surveyName" type="text" className="form-control" />
          </FormGroup>
          <FormGroup as={Col} className="form-group col-md-4 col-12">
            <label htmlFor="courseStartDate">Course start date</label>
            <Field id="courseStartDate" name="courseStartDate" type="date" className="form-control" />
          </FormGroup>
        </Row>
        <div className="form-submission">
          <Button variant="primary" block="block" type="submit" className="btn-operation">
            Search
          </Button>
        </div>
      </Form>
    </Formik>
  );
};
HistorySearch.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    activityCode: PropTypes.string,
    instanceCode: PropTypes.string,
    courseTitle: PropTypes.string,
    surveyName: PropTypes.string,
    courseStartDate: PropTypes.string,
  }).isRequired,
};

export default HistorySearch;
